import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction004 from './Introduction004'
import Introduction005 from './Introduction005'
import Introduction006 from './Introduction006'
import Introduction007 from './Introduction007'
import Introduction008 from './Introduction008'
import Introduction009 from './Introduction009'
import Introduction010 from './Introduction010'
import Introduction011 from './Introduction011'
import Introduction012 from './Introduction012'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;

  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }

  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;

    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }

    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;

      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }

      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;

        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }

      &.txt-color-red {
        color: #ff6969;
      }
    }

    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;

      > div {
        width: 50px;
      }

      .link-p {
        width: calc(100% - 50px);

        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;

          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }

    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }

  ${BreakPoints.large} {
  }
`
type Props = {
  /** 広告の番号 */
  no: number
}

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0061 = (props: Props): JSX.Element => (
  <Wrapper>
    <ColumnH1 label="教員の働き方改革！働き方改革が必要な理由・進めるための課題とは？" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2023.12.08</p>
    <section>
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        次代の社会を担う子供達の教育および人間形成に寄与する最も近い立場にある教員においても働き方改革の必要性が挙げられています。旧来、受け持ちの授業だけでなく、部活動の指導や保護者対応等、業務の多さは多方面から指摘されており、長時間労働の代表的な業種にも挙げられていました。そこで、今回はなぜ教員の働き方改革が必要なのか、そして、働き方改革を進めるにあたっての課題について解説します。{' '}
      </p>
      <img src="/images/column/details/c0061.jpg" alt="キンクラコラム画像61" />
      <h1 id="toc1">教員の働き方改革が必要な理由</h1>
      <p>
        公立学校の教員公務員についても一部の規定を除き労働基準法が適用されることから、同法の制約の範囲内において対応が必要とされています。旧来、次代の社会を担う子供達のためであれば長時間労働も厭わないという文化がありました。しかし、そのような文化は一時的には問題にならなかったとしても、
        <span>
          長期的な視点に立つと、教員が疲弊していってしまうことは想像に難くなく、そうなることで、結果的には子供達にとってマイナスに働いてしまう
        </span>
        ことも指摘されています。具体的な長時間労働の原因は千差万別ではあるものの、一般的には部活動や授業の準備、学校行事、成績処理等が挙げられます。いずれも子供達にとっては必要なものであり、小学校においては6年間、中学校においては3年間という絶対的に限られた期間の中では欠かすことはできません。言い換えると前述の業務はゼロにすることはできず、
        <span>改善を加えながら働き改革を進めなければならない</span>
        ということです（もちろん高等学校も義務教育ではないものの教員の働き方改革は道半ばです）。
      </p>

      <h1 id="toc2">働き方改革を進めるための課題とは</h1>
      <p>
        長時間労働を減らすことの必要性は理解されているものの、単に職場におけるタイムカードのみでは本当に長時間労働が減っていることの確認が取れていると結論付けるのは時期尚早です。教員の場合、全てではないにせよ、持ち帰り残業の事実が明らかになっており、職場と自宅の境界がなく、労務の提供が行われていることが問題になっています。具体的には
        <span>
          成績処理や授業の準備等、「授業中」以外にも教員の労働実態はあり、抜本的な解決を図るにはある程度の「増員」は必要不可欠
        </span>
        と言えます。ただし、この点については明確な課題があり、担任を持つ教員の場合、子供達とはある程度時間をかけて信頼関係を築いていく必要性が高く、一般企業のように業務の分担や業務の割り振りをすることが必ずしも適切とは言えません。しかし、全て教員が対応するようでは課題が解決することはありませんので、
        <span>
          子供達に直接的な影響の少ない部分を外部委託し、負担を緩和する
        </span>
        必要性が高いと言えます。また、昨今は部活動の「外部指導員」の活用が積極的に検討されており、地域の有識者の力を借りることで教員は授業へより注力することができ、部活動としてもより専門的な指導が可能となる相乗効果が期待されています。
      </p>

      {/** ここに9パターンの広告設定する */}
      {props.no === 0 && <Introduction004 />}
      {props.no === 1 && <Introduction005 />}
      {props.no === 2 && <Introduction006 />}
      {props.no === 3 && <Introduction007 />}
      {props.no === 4 && <Introduction008 />}
      {props.no === 5 && <Introduction009 />}
      {props.no === 6 && <Introduction010 />}
      {props.no === 7 && <Introduction011 />}
      {props.no === 8 && <Introduction012 />}

      <h1 id="toc3">改革事例集</h1>
      <h2 id="toc3-1">部活動の指導時間見直し</h2>
      <p>
        地域の小中学校から選手を募集して土日祝日に活動するいわゆるクラブチームと学校の部活動の違いとして、部活動の場合は土日祝日に固執せず活動できることが保護者目線でもメリットに感じられているようです。ただし、長い時間活動したからといって必ず技術力等が向上するかと言うとそれは別問題となります。特に心身ともに著しい成長途上にある中学生の場合、長時間の練習が祟ってしまい怪我に繋がることも少なくありません。そこで、特定の曜日を休養日として静養に努め、かつ、外部指導員を確保できていない小中学校等であれば教員も生徒と同様に静養に充てるという取り組み事例です。
      </p>
      <h2 id="toc3-2">ICTの積極活用</h2>
      <p>
        欠席、遅刻、早退の連絡を都度電話連絡と定めている場合、もちろん実際の生の声を聴くことで、連絡の信憑性が高くなるのは明らかですが、都度対応するにあたって時間が取られてしまいます。そこで、これらの連絡をICTツール化することで特に多忙となりがちな朝の時間帯を有効活用できるようになった事例です。ICTツール化することで
        <span>
          集計作業も自動でできることからミスも防ぐことができ、業務の負担軽減につながる
        </span>
        ことは明らかです。
      </p>
      <h2 id="toc3-3">ガイドブック作成</h2>
      <p>
        子供よりもむしろ親の方が（子供のことを）心配しているということは小中学校等に限らず珍しくありません。そこで、保護者からの問い合わせを減らす目的（ひいては教員の時間確保）で、
        <span>高頻度で寄せられる質問や相談を「ガイドブック」</span>
        、少なくとも当該ガイドブックに
        <span>掲載されている内容についての問い合わせは減らす</span>
        ことが可能です（もちろん見落としや派生論点での問い合わせはあり得る）
      </p>

      <h1 id="toc4">最後に</h1>
      <p>
        教員の場合、専門性の高さゆえに周囲に相談ができず、業務量だけが増えていくということも少なくありません。教員だけでなく、事務職員や地域の有識者と連携（連携すべきタスクの線引きは必要であるものの）することで、可能な限り教員の負担軽減が図れるものです。そして、教員が健康な状態で働き続けられることはそれを直接目にする子供達にとってもプラスになる改革と言えます。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0061
